import { useState, useEffect } from 'react';
import { getUserdata } from '../utilities/sessionexpiry';

export default function WhatsappWidget(props){
    const {
      store,
      social,
      description,
      assets,
      storeid,
      storecode,
      hdimage,

    } = props;

  const [isEnabled, setIsEnabled] = useState(true);
  const [pageInit, setPageInit] = useState(false);
  const [userData, setUserData] = useState(null);

  // Parse social data and extract WhatsApp contact details
  let socialData = [];
  if (typeof social === 'string') {
    try {
      socialData = JSON.parse(social);
    } catch (error) {
      console.error('Error parsing social data:', error);
    }
  } else {
    socialData = social;
  }

  const whatsappContact = socialData?.find(item => item.name.toLowerCase() === 'whatsapp');

  let whatsappNumber = '';
  let text = '';

  if (whatsappContact) {
    const contactValue = whatsappContact.value;
    if (contactValue.startsWith('https://api.whatsapp.com/send')) {
      const urlParams = new URLSearchParams(contactValue.split('?')[1]);
      whatsappNumber = urlParams.get('phone');
      text = urlParams.get('text');
    } else {
      whatsappNumber = contactValue;
      text = encodeURIComponent(`Hello, I am looking for ${store}.`);
    }
  }

  // Function to load WhatsApp widget script
  const loadWhatsAppScript = () => {
    const existingScript = document.querySelector('script[src*="watiWidget.js"]');
    if (existingScript) return; // If script is already loaded, don't load it again

    const url = `https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?12205&t=${Date.now()}`; // Add timestamp to prevent caching
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;

    script.onload = () => {
      if (window.CreateWhatsappChatWidget) {
        const options = {
          enabled: isEnabled,
          chatButtonSetting: {
            backgroundColor: '#00e785',
            borderRadius: '25',
            marginLeft: '0',
            marginRight: '20',
            marginBottom: '20',
            ctaIconWATI: false,
            position: 'right',
          },
          brandSetting: {
            brandName: store,
            brandSubTitle: store,
            brandImg: `https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/${storeid}/favicon.webp`,
            welcomeText: 'Hi there!\nHow can I help you?',
            messageText: `${text}.`,
            backgroundColor: '#00e785',
            ctaText: 'Chat with us',
            borderRadius: '25',
            autoShow: false,
            phoneNumber: whatsappNumber,
          },
        };
        window.CreateWhatsappChatWidget(options);
      } else {
        console.error('WhatsApp widget script did not load correctly.');
      }
    };

    script.onerror = () => {
      console.error('Failed to load the WhatsApp widget script.');
    };

    // Append the script to the document
    document.body.appendChild(script);
  };

  const initializeWhatsappChatWidget = () => {
    const isOnCheckoutPage = window.location.pathname === '/checkout';
    const isOnCartPage = window.location.pathname === '/cart';

    if (isOnCheckoutPage || isOnCartPage) {
      setIsEnabled(false);
      return;
    }

    if (isEnabled) {
      loadWhatsAppScript();
    }
  };

  useEffect(() => {
    if (social && !pageInit) {
      setPageInit(true);
      setUserData(getUserdata());
      initializeWhatsappChatWidget();
    }
  }, [social, pageInit]); // Only run on social data change or initial page load

  return null; // No UI component is rendered directly
};

