import React, { useState } from 'react'
import logo from './Homepage/Images/MH-logo.webp'
import { Col, Container, Row} from 'react-bootstrap'
import playstore from './Homepage/Images/playstore.png'
import './style2.css'

import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { faLocationDot } from "react-icons/fa";
import { IoMailOpenOutline } from "react-icons/io5";

import 'bootstrap/dist/css/bootstrap.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube,faInstagram,faFacebook,faTiktok } from '@fortawesome/free-brands-svg-icons';

import toast, { Toaster } from "react-hot-toast";
import FadeLoader from "react-spinners/FadeLoader";
import { AxiosPost } from "../utilities/axioscall";
import { AK } from "../constants/AppKeys";

export default function  Footer (props){

  const {
    store,
    description,
    assets,
    storeid,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    assetsUrl,
    categoryListData,
    versionmanagerListData,
    colorcodesListData,
    productscolorcodesListData,
    sizeListData,
    productssizeListData,
    productsListData,
    pageRefresh,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;
  const mainCategories = categoryListData?.filter(category => category.parent_id === 0);

// Then, sort the main categories based on their IDs
mainCategories?.sort((a, b) => a.id - b.id);

 const [formValues, setFormValues] = useState({
    newsemail: "",
    newsemailError: false,
    formloading: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    var error = false;



    if (formValues.newsemail == "") {
      handleChange(
        {
          target: {
            value: true,
            name: "newsemailError"
          }
        }
      )

      error = true;
    } else {
      handleChange(
        {
          target: {
            value: false,
            name: "newsemailError"
          }
        }
      )
    }


    if (error) return;
    // Handle form submission logic here
    // Example: Logging form data
    console.log(formValues);


    handleChange(
      {
        target: {
          value: true,
          name: "formloading"
        }
      }
    )
    var formdata = {


      "email": formValues.newsemail,
    }
    return await AxiosPost(AK.NEWSLETTERFORMAPI, formdata)
      .then((res) => {

        if (res != typeof undefined && res.data != typeof undefined) {
          if (res?.data) {
            notify()
            setFormValues({
              newsemail: "",
              newsemailError: false,
              formloading: false
            })
            handleChange(
              {
                target: {
                  value: false,
                  name: "formloading"
                }
              }
            )

            return true;
          } else {
            notifyerror("Newsletter validation error.")

          }
        }



        handleChange(
          {
            target: {
              value: false,
              name: "formloading"
            }
          }
        )
        return true;
      })
      .catch((error) => {
        console.log(error)
        // notifyerror("Newsletter validation error.")


        handleChange(
          {
            target: {
              value: false,
              name: "formloading"
            }
          }
        )
        return false;
      });
    // Optionally, you can redirect after form submission
    // navigate("/thank-you"); // Replace "/thank-you" with your desired route
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const notify = () =>
    toast.success("Newsletter subscribed successfully.", {
      style: {
        border: "1px solid #00b852",
        padding: "16px",
        color: "#00b852",
      },
      iconTheme: {
        primary: "#00b852",
        secondary: "#FFFAEE",
      },
    });

  const notifyerror = (msg) =>
    toast.error(msg, {
      style: {
        border: "1px solid #cc0000",
        padding: "16px",
        color: "#cc0000",
      },
      iconTheme: {
        primary: "#cc0000",
        secondary: "#FFFAEE",
      },
    });

  return (
<>
<Container fluid >
   <div className="row footer1 p-md-5">
        <div className="col-12 col-md-3 pt-3">
          <ul className='ps-0'>
            <h5 className='pb-3'>CONTACT DETAILS</h5>
            <li className='d-flex items-start'><FaMapMarkerAlt  className='mt-2'/><p className='ps-4 ms-1 pb-3'>13-01, Jalan Kota 2, Kawasan Perindustrian Kota Puteri, 81750 Masai, Johor, Malaysia.
</p></li>

            <li className='d-flex items-center'><FaPhoneAlt /><a href='tel:+60 167840475' target='_blank' className='ps-3' style={{color:'#999999'}}>  +60 167840475</a></li>
            <li className='d-flex items-center'><FaPhoneAlt /><a href='tel:+60 146199369' target='_blank' className='ps-3' style={{color:'#999999'}}>  +60 146199369</a></li>
            <li className='d-flex  items-center'><IoMailOpenOutline /><a href='mailto:aathiraicraftz@gmail.com' target='_blank' className='ps-3'style={{color:'#999999'}}> aathiraicraftz@gmail.com </a> </li>
            </ul>
            <ul className='d-flex py-4'>
           <a href='https://www.facebook.com/aathiraicraftz/' target='_blank' className='ps-4 ps-md-0'><FontAwesomeIcon icon={faFacebook} size="2xl" style={{color: "#ffffff",}} /></a>
           <a href='https://www.instagram.com/aathiraicraftz/'  target='_blank' className='ps-4'><FontAwesomeIcon icon={faInstagram} size="2xl" style={{color: "#ffffff",}} /></a>
           <a href='https://www.youtube.com/@aathiraicraftzmalaysia1' target='_blank' className='ps-4'>  <FontAwesomeIcon icon={faYoutube} size="2xl" color="white" /></a>
           <a href='https://www.tiktok.com/@aathiraicraftz?_t=8s7V3kgDhAu&_r=1' target='_blank' className='ps-4'>  <FontAwesomeIcon icon={faTiktok} size="xl"  style={{color: "#ffffff",}} /></a>
           </ul>
        </div>
        <div className="col-12 col-md-3 pt-3">
          <ul className='ps-0'>
            <h5 className='pb-3'>CATEGORY </h5>
            {mainCategories?.length > 0 ? (
                mainCategories.slice(0, 8).map((category) => (
                    <div>
                      <a className="categorylist" href={`/products/${category.seo_url}`}  value={category.id}>{category.name}</a>     
                  </div>
                ))
              ) : (
                <p>Loading categories...</p>
              )}
          </ul>
        </div>
        <div className="col-12 col-md-3 pt-3">
          <ul className='ps-0'>
            <h5 className='pb-3'> INFORMATION</h5>
            <li> <a href='/pages/aboutus' style={{color:'#999999'}}> About Us</a> </li>
            <li> <a href='/pages/privacypolicy' style={{color:'#999999'}}> Privacy Policy</a> </li>
            <li><a href='/pages/termsandconditions' style={{color:'#999999'}}>Terms & Conditions</a></li>
            <li><a href='/pages/shippingandrefundpolicy' style={{color:'#999999'}}>Shipping & Delivery </a></li>
            <li><a href='/trackorder' style={{color:'#999999'}}>Tracker Order</a></li>

            <li><a href='/pages/contactus' style={{color:'#999999'}}>Contact</a></li>
            <li><a href='/storelocation' style={{color:'#999999'}}>Our Store</a></li>
            <li><a href='/blog' style={{color:'#999999'}}>Blog</a></li>
          </ul>
        </div>
        <div className="col-12 col-md-3 pt-3 input-con">
            <h5 className='pb-0'> SUBSCRIBE OUR NEWSLETTER</h5> <br /> <span className='mb-3 input-text'>Subscribe and Stay up to date with our latest offers</span>
          

<div className="input-group input-group2 mb-3">
  <input type="text" className="form-control "  id="newsemail"
                        name="newsemail"
                        value={formValues.newsemail}
                        onChange={handleChange} placeholder="Enter your email address" aria-label="Recipient's username" aria-describedby="button-addon2"/>
  <button className="btn btn-outline-secondary"  type="button"
                      onClick={(e) => {
                        handleSubmit(e);
                      }} id="button-addon2" style={{color:'white',fontFamily: 'Roboto',
fontSize: '14px',
fontWeight: 500,
textAlign: 'center',
}}>Subscribe</button>
<FadeLoader loading={formValues.formloading} width={"2px"} height={"10px"} color="rgb(84 117 229)" />
</div>
 <p
                  className="mb-2 text-red-500"
                  hidden={
                    !formValues.newsemailError
                  }
                >
                  Please enter your email.
                </p>
                <Toaster />
{/* <div className='sec-con'>
<h5 className='py-3 '>DOWNLOAD OUR APP</h5> 
<img src={playstore} className='img img-fluid py-3 pt-md-1' width={'150px'} alt="" /></div> */}
        </div>
        <hr />
        <center> <p className='footer-text'><span style={{color:'#999999'}}>Copyright © </span>Aathirai Craftz<span style={{color:'#999999'}}> all rights reserved. Developed by </span><a target='_blank' className='font-bold'style={{color:"white"}} href="https://www.vilvabusiness.com/">VilvaBusiness</a> </p></center> 
        </div>

    

    </Container>
    </>
  )
}