
import React, { useState } from 'react';
import "./customstyle.css";

function NotfoundPage(props) {

  return (
    <div className='d-flex justify-center align-items-center ' style={{height:"100vh"}} >
        <div className='notfound'> 
            <img src={"https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/404_img.webp"} alt="404"  height={"300px"}  width={"300px"} className="img-fluid" />
            <h1>OOPS....</h1>
            <h2>404 Error Page not found</h2>
            <p>Sorry, the page you're looking for doesn't exist.</p>
            <a href="/">Go Back Home</a>
        </div>
 

    </div>
  );
}

export default NotfoundPage;